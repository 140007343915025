/* SUBMENU */
.{prefix}-container
    .{prefix}-submenu
        display: none;
        position: absolute;
        bottom: 0;
        width:100%;
        height: 150px;
        white-space: nowrap;
        z-index: 2;
        .{prefix}-button:hover svg > use.active
            display: block;
        .{prefix}-submenu-item
            li
                display: inline-block;
                vertical-align: top;
            .{prefix}-newline
                display: block;
                margin-top: 0;
            .{prefix}-button
                position: relative;
                cursor: pointer;
                display: inline-block;
                font-weight: normal;
                font-size: 11px;
                margin: 0 9px 0 9px;
            .{prefix}-button.preset
                margin: 0 9px 20px 5px;
            label > span
                display: inline-block;
                cursor: pointer;
                padding-top: 5px;
                font-family: "Noto Sans", sans-serif;
                font-size: 11px;
            .{prefix}-button.apply label,
            .{prefix}-button.cancel label
                vertical-align: 7px;
        > div
            display: none;
            vertical-align: bottom;
        .{prefix}-submenu-style
            opacity: 0.95;
            z-index: -1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;

    .{prefix}-partition > div
        width: 1px;
        height: 52px;
        border-left: 1px solid #3c3c3c;
        margin: 0 8px 0 8px;
    .{prefix}-main.{prefix}-menu-filter .{prefix}-partition > div
        height: 108px;
        margin: 0 29px 0 0px;
    .{prefix}-submenu-align
        text-align: left;
        margin-right: 30px;
        label > span
            width: 55px;
            white-space: nowrap;
    .{prefix}-submenu-align:first-child
        margin-right: 0;
        label > span
            width: 70px;
    .{prefix}-main.{prefix}-menu-crop .{prefix}-submenu > div.{prefix}-menu-crop,
    .{prefix}-main.{prefix}-menu-flip .{prefix}-submenu > div.{prefix}-menu-flip,
    .{prefix}-main.{prefix}-menu-rotate .{prefix}-submenu > div.{prefix}-menu-rotate,
    .{prefix}-main.{prefix}-menu-shape .{prefix}-submenu > div.{prefix}-menu-shape,
    .{prefix}-main.{prefix}-menu-text .{prefix}-submenu > div.{prefix}-menu-text,
    .{prefix}-main.{prefix}-menu-mask .{prefix}-submenu > div.{prefix}-menu-mask,
    .{prefix}-main.{prefix}-menu-icon .{prefix}-submenu > div.{prefix}-menu-icon,
    .{prefix}-main.{prefix}-menu-draw .{prefix}-submenu > div.{prefix}-menu-draw,
    .{prefix}-main.{prefix}-menu-filter .{prefix}-submenu > div.{prefix}-menu-filter
        display: table-cell;
    .{prefix}-main.{prefix}-menu-crop,
    .{prefix}-main.{prefix}-menu-flip,
    .{prefix}-main.{prefix}-menu-rotate,
    .{prefix}-main.{prefix}-menu-shape,
    .{prefix}-main.{prefix}-menu-text,
    .{prefix}-main.{prefix}-menu-mask,
    .{prefix}-main.{prefix}-menu-icon,
    .{prefix}-main.{prefix}-menu-draw,
    .{prefix}-main.{prefix}-menu-filter
      .{prefix}-submenu
        display: table;

